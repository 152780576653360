export default function lazyIframe() {
	var hasLoaded = false;

	if(window.scrollY >= 20){ 
		hasLoaded = true;
		loadIframe();
	}

	window.addEventListener('scroll', function(){
		if(window.scrollY >= 20 && !hasLoaded){ 
			hasLoaded = true; 
			loadIframe(); 
		}
	});

	function loadIframe(){
		var lzyIframe = document.querySelector('iframe[data-src]');

		if(lzyIframe){
			lzyIframe.setAttribute('src', lzyIframe.dataset.src);
		}
	}
}